import {
  Components, ColorProfiles,
} from '@powdr/constants';

// Color Profiles
const {
  BASE, PRIMARY, SECONDARY, TERTIARY, QUATERNARY,
} = ColorProfiles;

export const config = {
  [Components.HEADER]: BASE,
  [Components.MAIN_NAV]: BASE,
  [Components.MEGA_NAV]: BASE,
  [Components.MOBILE_NAV]: BASE,
  [Components.ECOMMERCE_BANNER]: SECONDARY,
  [Components.WEATHER_FEED]: BASE,
  [Components.PROMOTIONS_FEED]: PRIMARY,
  [Components.PROMOTIONS]: BASE,
  [Components.ALERT_BAR]: BASE,
  [Components.STICKY_NAV]: SECONDARY,
  [Components.TERTIARY_NAV]: BASE,
  [Components.LEVELS_MODAL]: TERTIARY,
  [Components.FOOTER]: SECONDARY,
  [Components.FOOTER_NAV]: SECONDARY,
  [Components.PARTNERS]: SECONDARY,
  [Components.COPYRIGHT]: TERTIARY,
  [Components.MODAL]: QUATERNARY,
  [Components.TV]: TERTIARY,
  layout: {
    headerHeight: 90,
    headerHeightMobile: 60,
    headerLogoContainerWidth: 260,
    headerLogoHeight: 30,
    headerFeedContainer: 240,
  },
};
